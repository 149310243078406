/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

// !Theme1 blue + creamywhite
// $theme-colors: (
    
//     "primary":    #0A174E,
//     "secondary":  #fffbed,
//     "tertiary":    #FFFFFF,
//     "textprimary":  #000000,
//     "textsecondary":    #ffffff,
// );

// !Theme1 tile + green-white
// $theme-colors: (
    
//     "primary":    #008080,
//     "secondary":  #e6fdea,
//     "tertiary":    #FFFFFF,
//     "textprimary":  #000000,
//     "textsecondary":    #ffffff,
// );
//! Deep blue -White
// $theme-colors: (
    
//     "primary":    #0063B2FF,
//     "secondary":  rgb(243, 244, 245),
//     "tertiary":    #FFFFFF,
//     "textprimary":  #000000,
//     "textsecondary":    #ffffff,
// );

// //!Primary
// .gurukul-font-primary-style{
//     font-family: "Playwrite IN", cursive;
//     font-optical-sizing: auto;
//     font-weight: 200;
//     font-style: normal;
// }


.gurukul-font-primary{

}

.gurukul-font-secondary{

}
.text-hovergstylep{
	// position: relative;
	// text-align: center;
	
	&:hover{
		transform:   scale(1.01);
		color: #d81a1a;
	}
}

.head-text-hovergstylep{
	// position: relative;
	// text-align: center;
	
	&:hover{
		transform:   scale(1.08);
		// background-color: rgb(250, 250, 219);
		text-decoration: underline;
		text-decoration-thickness: 1px;
	}
}


// .head-text-hovergstylepa {
// 	background-image: linear-gradient(
// 	  to right,
// 	  #54b3d6,
// 	  #54b3d6 50%,
// 	  #000 50%
// 	);
// 	background-size: 200% 100%;
// 	background-position: -100%;
// 	-webkit-text-fill-color: transparent;
// 	transition: all 0.3s ease-in-out;
//   }

.text-hovergstyles{
	// position: relative;
	// text-align: center;

	transition: all 0.8s ease-in-out !important;
	&:hover{
		transform:   scale(1.02);

	}
}

.carousel-hovergstyles{
	// position: relative;
	// text-align: center;
	transition: all 0.8s ease-in-out !important;
	&:hover{
		transform:   scale(1.06);

	}
}


.gurukul-font-primary-style{
    font-family: "Playwrite IN", cursive;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}
//! Narvik (#EAE7DD), Sorrell brown (#99775C)
// $theme-colors: (
    
//     "primary":    #EAE7DD,
//     "secondary":  #99775C,
//     "tertiary":    #FFFFFF,
//     "textprimary":  #000000,
//     "textsecondary":    #ffffff,
// );
$theme-colors: (
    "primary":    #0063B2FF,
    "secondary":  rgb(229, 231, 233),
    "tertiary":    #FFFFFF,
    "textprimary":  #000000,
    "textsecondary":    #ffffff,
);



// !100%  
// !    60-Primary
// !    30-Secondary
// !    10-tertiary

//https://visme.co/blog/website-color-schemes/#, #, #



@import '../node_modules/bootstrap/scss/bootstrap.scss';

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
